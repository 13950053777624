import { PhotoLibrary, VideoLibrary } from '@mui/icons-material';
import { Box, BoxProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AssetName, AssetPlaceholderContainer, Dimmer, MediaPreviewContainer } from './AssetsPreview.style';
import VideoThumbnail from './VideoThumbnail';
interface MediaPreviewProps extends BoxProps {
  inputValue: string;
  mediaType: 'image' | 'video';
  filename?: string;
  onClick?: () => void;
}
function MediaPreview({
  inputValue,
  mediaType,
  filename,
  onClick,
  ...delegatedBoxProps
}: MediaPreviewProps) {
  return <MediaPreviewContainer onClick={onClick} {...delegatedBoxProps}>
			{inputValue ? <>
					<Asset inputValue={inputValue} mediaType={mediaType} />

					<Dimmer />

					<AssetName>
						<span title={filename ?? inputValue}>{filename ?? inputValue}</span>
					</AssetName>
				</> : <AssetPlaceholder mediaType={mediaType} />}
		</MediaPreviewContainer>;
}
export function VideoPreview(props: Omit<MediaPreviewProps, 'mediaType'>) {
  return <MediaPreview mediaType='video' {...props} />;
}
export function ImagePreview(props: Omit<MediaPreviewProps, 'mediaType'>) {
  return <MediaPreview mediaType='image' {...props} />;
}
interface AssetProps {
  inputValue: string;
  mediaType: 'image' | 'video';
}
function Asset({
  inputValue,
  mediaType
}: AssetProps) {
  if (inputValue && mediaType === 'image') {
    return <img src={inputValue} />;
  }
  if (inputValue && mediaType === 'video') {
    return <VideoThumbnail src={inputValue} />;
  }
  return null;
}
function AssetPlaceholder({
  mediaType
}: Pick<MediaPreviewProps, 'mediaType'>) {
  if (mediaType === 'video') {
    return <AssetPlaceholderContainer>
				<VideoLibrary fontSize='small' />
				<Box ml={0.5}>
					<FormattedMessage id='edition_menu.video.add_video' />
				</Box>
			</AssetPlaceholderContainer>;
  }
  return <AssetPlaceholderContainer>
			<PhotoLibrary fontSize='small' />
			<Box ml={0.5}>
				<FormattedMessage id='edition_menu.image.add_image' />
			</Box>
		</AssetPlaceholderContainer>;
}