import { Box, Button, Typography } from '@mui/material';
import PageActionButtonsSettings from 'components/PageActionButtonsSettings';
import PageAnswerButtonsSettings from 'components/PageAnswerButtonsSettings';
import PageFormSettings from 'components/PageFormSettings';
import PageTemplateSelector from 'components/PageTemplateSelector';
import WheelSettings from 'components/WheelSettings';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import useStoryPagesOptions from 'lib/hooks/useStoryPagesOptions';
import { Page } from 'lib/models';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import { ModalWrapper } from '..';
export default function CreatePageModal({
  trigger,
  index
}) {
  const ref = useRef();
  const intl = useIntl();
  const dispatch = useStoryDispatch();
  const redirectOptions = useStoryPagesOptions({
    countCurrentPage: true
  });
  const draftStory = useStoryState(s => s.draftStory);
  const recently_used = useStoryState(s => s.draftStory?.recently_used);
  const brand_colors = useStoryState(s => s.draftStory?.brand_colors);
  const brand_fontFamily = useStoryState(s => s.draftStory?.brand_fontFamily);
  const isSinglePage = draftStory.type === 'single_page';
  const isAmp = draftStory.type === 'amp';
  const [pageTypeMetaData, setPageTypeMetaData] = useState(isAmp ? {
    page_type: 'amp_page',
    sub_type: 'none',
    description: 'blank_none'
  } : {
    page_type: 'all',
    sub_type: 'none',
    description: 'blank_none'
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [newPage, setNewPage] = useState({});

  // Select a type of page (blank, game, media_answer, multiple_choice, form, rating)
  // Create new page
  // Apply template layout (if create with layout)

  const [step, setStep] = useState(0);
  function onRecentColorsUpdate(color) {
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'colors.block',
        value: color
      }
    }, false);
  }
  function createPage(data) {
    if (isSinglePage) {
      dispatch({
        type: 'SINGLE_TO_CLASSIC_PAGE',
        data
      });
    } else {
      dispatch({
        type: 'CREATE_PAGE',
        data
      });
    }
  }
  async function onTemplateSelected(pageLayout) {
    if (isProcessing) return;
    setIsProcessing(true);
    const new_page = isAmp ? new Page().setType({
      type: 'amp_page',
      sub_type: 'none',
      currentFontFamily: draftStory.theming?.textBlock?.fontFamily,
      lang: draftStory.story_settings.language
    }).setName(intl.messages['modals.create_page.title']) : new Page().setType({
      type: pageLayout?.type || 'blank',
      sub_type: getLayoutSubType(pageLayout),
      messages: draftStory.messages,
      variables: draftStory.variables,
      lang: draftStory.story_settings.language
    }).setName(intl.messages['modals.create_page.title']);
    if (pageLayout) {
      const page_layout = await PublishedStoriesSvc.convertPageTemplate({
        project: draftStory.project,
        pageTemplate: pageLayout
      });
      new_page.changeLayout(page_layout, draftStory.variables, true);
    } else if (!_.isEmpty(draftStory?.recently_used?.background)) {
      // Apply current background color
      new_page.setBackgroundElement(draftStory?.recently_used?.background);
    }
    setNewPage(_.cloneDeep(new_page));

    // 2 cases
    // If multiple_choice or form, go to step 2
    // If not, create page

    if (['multiple_choice', 'form', 'game'].includes(new_page?.type)) {
      setStep(isSinglePage ? 2 : 1);
    } else {
      createPage({
        insert_position: index,
        newPage: new_page
      });
      ref.current.close();
    }

    // Avoid spaming create from scratch button which causes multiple pages creation with the same _id
    await sleep(1000);
    setIsProcessing(false);
  }
  function onSubmitConfig({
    new_page,
    variables,
    gdprConsent,
    display_live_results
  }) {
    createPage({
      insert_position: index,
      newPage: new_page,
      variables,
      gdprConsent,
      display_live_results
    });
    ref.current.close();
  }
  function onPreviousStep() {
    if (step > 0) {
      setStep(step - 1);
    } else {
      setStep(0);
    }
  }
  function onNextStep() {
    setStep(step + 1);
  }
  function resetState() {
    if (isAmp) {
      setPageTypeMetaData({
        page_type: 'amp_page',
        sub_type: 'none',
        description: 'blank_none'
      });
    } else {
      setPageTypeMetaData({
        page_type: 'all',
        sub_type: 'none',
        description: 'blank_none'
      });
    }
    setStep(0);
  }
  function renderResponseSettings() {
    if (newPage?.type === 'multiple_choice') {
      if (newPage?.answers_properties?.type === 'answer') {
        return <PageAnswerButtonsSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={newPage} draftStory={draftStory} buttonType={_.first(newPage?.answers).type} />;
      }
      return <PageActionButtonsSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={newPage} draftStory={draftStory} />;
    }
    if (newPage?.type === 'form') {
      return <PageFormSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={newPage} draftStory={draftStory} />;
    }
    if (newPage?.type === 'game') {
      return <WheelSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={newPage} draftStory={draftStory} recentColors={recently_used?.colors?.block} rencentFonts={recently_used?.font_families} brandColors={brand_colors} brandFontFamily={brand_fontFamily} updateRecentColor={onRecentColorsUpdate} />;
    }
    return null;
  }
  return <ModalWrapper title={generateModalTile(intl, step, isSinglePage)} trigger={trigger} ref={ref}
  // Avoid close modal when editing interacttion
  disableBackdropClick={step === 1} hasCloseButton onClose={resetState} size={isSinglePage && step === 0 ? 'sm' : 'lg'} PaperProps={{
    sx: {
      height: '100%'
    }
  }}>
			{isSinglePage ? <>
					{step === 0 && <>
							<Box px={3} py={1}>
								<Typography variant='h3'>
									<FormattedMessage id='modals.create_page.single_to_classic_page' />
								</Typography>
							</Box>
							<Box width='100%' p={1} display='flex' justifyContent='flex-end'>
								<Button variant='outlined' color='secondary' onClick={() => onNextStep()}>
									<FormattedMessage id='button.continue' />
								</Button>
							</Box>
						</>}
					{step === 1 && <PageTemplateSelector isProcessing={isProcessing} onSelect={onTemplateSelected} pageType={pageTypeMetaData?.page_type} buttonsType={pageTypeMetaData?.button_group_type} isAmp={isAmp} />}

					{step === 2 && renderResponseSettings()}
				</> : <>
					{step === 0 && <PageTemplateSelector isProcessing={isProcessing} onSelect={onTemplateSelected} pageType={pageTypeMetaData?.page_type} buttonsType={pageTypeMetaData?.button_group_type} isAmp={isAmp} />}

					{step === 1 && renderResponseSettings()}
				</>}
		</ModalWrapper>;
}
CreatePageModal.propTypes = {
  trigger: PropTypes.object,
  index: PropTypes.number
};

// Generate title of modal via step
function generateModalTile(intl, step, isSinglePage = false) {
  if (isSinglePage) {
    switch (step) {
      case 0:
        return intl.messages['modals.create_page.add_page_to_single_page'];
      case 1:
        return intl.messages['modals.create_page.step_title.template'];
      case 2:
        return intl.messages['modals.create_page.step_title.interaction'];
    }
  } else {
    switch (step) {
      case 0:
        return intl.messages['modals.create_page.step_title.template'];
      case 1:
        return intl.messages['modals.create_page.step_title.interaction'];
    }
  }
}
function getLayoutSubType(page) {
  if (page?.type === 'game') {
    return 'wheel';
  }
  return undefined;
}