import { Edit, PhotoLibraryOutlined, Restore, VideoLibraryOutlined, Visibility } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, FormControlLabel, IconButton, InputAdornment, Radio, TextField, Tooltip, Typography } from '@mui/material';
import styled from '@styled';
import BannerWidgetElement from 'components/BannerWidgetElement';
import { ImagesSelectorModal, ModalWrapper, VideosSelectorModal } from 'components/Modals';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId } from 'redux/project/selectors';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
const StyledDialogContent = styled(DialogContent)`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-row-gap: ${({
  theme
}) => theme.spacing(2)};
`;
const getDisplayedName = story => story?.display_name ?? story?.name ?? '';
function initializeState(story) {
  return {
    currentStory: story ?? {},
    display_name: getDisplayedName(story),
    thumbnail: story.thumbnail || story.snapshot_url_png || '',
    thumbnail_type: story.thumbnail_type || 'image',
    fileType: story.thumbnail_type || 'image' // Used for radio buttons
  };
}
const EditWidgetStoryModal = forwardRef(function EditWidgetStoryModal({
  widget,
  story,
  onStoryUpdate
}, ref) {
  const intl = useIntl();
  const [state, setState] = useImmer(initializeState(story));
  const [videos, setVideos] = useState([]);
  const organizationSlug = useSelector(state => state.organization?.slug);
  const project = useSelector(getCurrentProjectId);
  const onUploadSuccess = useCallback(uploadedFiles => {
    if (uploadedFiles.length === 1) {
      const {
        url
      } = uploadedFiles[0];
      setState(draft => {
        draft.thumbnail = url;
        draft.thumbnail_type = draft.fileType;
      });
    }
  }, [setState]);
  const onConfirm = useCallback(() => {
    const updatedStory = {
      story_id: state.currentStory.story_id
    };
    if (state.display_name !== getDisplayedName(state.currentStory)) {
      updatedStory.display_name = state.display_name;
    }
    if (state.thumbnail !== state.currentStory.snapshot_url_png) {
      updatedStory.thumbnail = state.thumbnail;
      updatedStory.thumbnail_type = state.fileType;
    }
    onStoryUpdate({
      ...state.currentStory,
      ...updatedStory
    });
    ref.current.close();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [state, onStoryUpdate]);
  const handleFieldChange = useCallback(field => ({
    target: {
      value
    }
  }) => {
    setState(draft => {
      draft[field] = value;
    });
  }, [setState]);
  const resetState = useCallback(() => {
    setState(draft => initializeState(story));
  }, [setState, story]);
  function onSelect({
    url
  }) {
    setState(draft => {
      draft.thumbnail = url;
      draft.thumbnail_type = draft.fileType;
    });
  }
  function onDisplayNameReset() {
    setState(draft => {
      draft.display_name = story.name;
    });
  }
  useEffect(() => {
    if (!_.isEqual(story, state.currentStory)) {
      resetState();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [story]);
  useAsyncEffect(async isMounted => {
    try {
      const publishStory = await PublishedStoriesSvc.get({
        story_id: story?.story_id,
        project
      });
      if (isMounted()) {
        const videos = getVideosFromPages(publishStory?.pages);
        setVideos(videos);
      }
    } catch (err) {
      console.log(err);
    }
  }, [story, project]);
  return <ModalWrapper size='md' ref={ref} title={<FormattedMessage id='modal.widget_banner.story_edit.header' />} onClose={resetState}>
			<StyledDialogContent>
				<Box display='flex' alignItems='center'>
					<Box mr={2}>
						<Typography color='textSecondary'>
							{widget.banner_type === 'card' ? <FormattedMessage id='modal.widget_banner.story_edit.caption' /> : <FormattedMessage id='modal.widget_banner.story_edit.display_name' />}
						</Typography>
					</Box>

					<TextField variant='standard' multiline={widget.banner_type === 'card'} maxRows={4} fullWidth value={state.display_name} onChange={handleFieldChange('display_name')} placeholder={intl.formatMessage({
          id: 'form.name'
        })} InputProps={{
          endAdornment: <InputAdornment position='end'>
									<Tooltip title='Réinitialiser le nom' placement='top'>
										<IconButton size='small' onClick={onDisplayNameReset}
              // onMouseDown={handleMouseDownPassword}
              >
											<Restore />
										</IconButton>
									</Tooltip>
								</InputAdornment>
        }} />
				</Box>

				<Box alignSelf='center' justifySelf='center' columnGap={2} display='flex'>
					<ModalWrapper trigger={<Button variant='outlined' startIcon={<Visibility />}>
								<FormattedMessage id='modal.widget_banner.story_edit.button.preview' />
							</Button>} size='xs' extendMaxWidth={false} title={`Story: ${state.display_name}`}>
						<iframe src={`${process.env.NEXT_PUBLIC_SNACKEET_URL}/${story.story_id}?preview`} width='400px' height='704px' data-hj-allow-iframe='' />
					</ModalWrapper>

					<Button variant='outlined' color='info' startIcon={<Edit />} href={`/${organizationSlug}/edit/${story.story_id}`} target='_blank'>
						<FormattedMessage id='modal.widget_banner.story_edit.button.editor' />
					</Button>
				</Box>

				<Box sx={{
        display: 'flex',
        gap: 2
      }}>
					<Typography color='textSecondary' sx={{
          mt: 1.5
        }}>
						<FormattedMessage id='form.thumbnail' />
					</Typography>

					<Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start'
        }}>
						<FormControlLabel control={<Radio checked={state.fileType === 'image'} onChange={handleFieldChange('fileType')} value='image' />} label={<FormattedMessage id='editor.button.background_edition.image' />} labelPlacement='end' />
						<FormControlLabel control={<Radio checked={state.fileType === 'video'} onChange={handleFieldChange('fileType')} value='video' />} label={<FormattedMessage id='editor.button.background_edition.video' />} labelPlacement='end' />
					</Box>

					<Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}>
						<Box sx={{
            pointerEvents: 'none'
          }}>
							<BannerWidgetElement widget={widget} story={{
              display_name: state.display_name,
              thumbnail: state.thumbnail,
              thumbnail_type: state.fileType
            }} size={computePreviewHeight(widget)} $disabledAnimation={true} />
						</Box>

						{state.fileType === 'image' ? <ImagesSelectorModal trigger={<Button variant='contained' color='primary' startIcon={<PhotoLibraryOutlined />} size='small'>
										<FormattedMessage id='modal.widget_banner.story_edit.button.change_image' />
									</Button>} inputValue={state.thumbnail} onSelect={onSelect} onUploadSuccess={onUploadSuccess} /> : <VideosSelectorModal trigger={<Button variant='contained' color='primary' startIcon={<VideoLibraryOutlined />} size='small'>
										<FormattedMessage id='modal.widget_banner.story_edit.button.change_video' />
									</Button>} inputValue={state.thumbnail} onSelect={onSelect} onUploadSuccess={onUploadSuccess} storyVideos={videos} />}
					</Box>
				</Box>
			</StyledDialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => ref.current?.close()}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
EditWidgetStoryModal.propTypes = {
  widget: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
  onStoryUpdate: PropTypes.func
};
export default EditWidgetStoryModal;

// Compute preview height
function computePreviewHeight(widget) {
  if (widget.banner_type === 'bubble') {
    const MAX_WIDGET_PREVIEW_SIZE = 200;
    const {
      size
    } = widget.placement.desktop;
    return _.min(size, MAX_WIDGET_PREVIEW_SIZE);
  }
  const MAX_WIDGET_PREVIEW_WIDTH = 400;
  let {
    height,
    width
  } = widget.placement.desktop;
  if (!height) {
    // Set proportional height if not present
    height = width * PREVIEW_HEIGHT / PREVIEW_WIDTH;
  }
  return _.min([140, MAX_WIDGET_PREVIEW_WIDTH * height / width]);
}
function getVideosFromPages(pages = []) {
  const videos = [];
  for (const page of pages) {
    // Get videos from background_element
    if (page.background_element?.type === 'video') {
      videos.push({
        url: page.background_element.value,
        poster: page.background_element?.poster
      });
    }
    for (const block of page?.blocks) {
      if (block?.type === 'video' && block?.value) {
        videos.push({
          url: block.value,
          poster: block?.poster
        });
      }
    }
  }
  return videos;
}